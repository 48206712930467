import { Col, Container, Row } from "react-bootstrap";
import "./aboutus.css";
const AboutUs = () => {
  return (
    <Container className="bg-body-primary text-center overflow-hidden heading-title container-aboutus">
      <div className="my-3 p-3">
        <h2 className="display-5 ">SELAMAT DATANG DI WORTEL21</h2>
        <p className="card-subtitle mb-2 text-muted">
          Ratusan Game Terbaru, 1 Akun Bisa Semua Game Sepuasnya!
        </p>
      </div>
      <Row className="aboutus-row justify-content-start ">
        <Col xs={4}>
          <div className="overlay-aboutus aboutus-image">
            <img
              src="/themes2/aboutus-asset01.png"
              alt="Overlay"
              className="overlay-aboutus-1"
            />
            <img
              src="/themes2/aboutus-overlay2.png"
              alt="Overlay2"
              className="overlay-aboutus-2"
            />
          </div>
        </Col>
        <Col xs={8} className="aboutus-text">
          <p>
            WORTEL21 merupakan situs game online terbaik untuk
            warga Indonesia. Rasakan sensasi yang telah dirasakan oleh
            pemain lainnya. Cek situs kami secara berkala, kami mempunyai game yang terbaru dan pastinya seru.
            Kami hanya memiliki permainan-permainan terbaik yang
            pastinya bisa bikin cuan terus!
          </p>
          <p>
            Sebagai wadah game online terpercaya, kami telah terakreditasi dan berlisensi
            resmi dari semua game provider. Kami juga memberikan kemudahan bagi
            pemain kami untuk memilih permainan game online yang dapat diakses
            dimanapun dan kapanpun.
          </p>
          <p>
            Keuntungan bermain game di WORTEL21 tentunya adalah rahasia Anda
            terjamin. Kami berkomitmen untuk terus meningkatkan nilai kepuasan
            pemain kami dengan menjaga kepercayaan yang menyediakan layanan Live
            Chat 24 Jam non stop. Beragam permainan game online yang telah kami
            sediakan dapat dipastikan tidak ada kecurangan dan fairplay.
          </p>
          <p>
            Tidak perlu modal besar, Anda bisa main dan pasti berpeluang
            menang juga untung banyak! Tunggu apa lagi? Gabung dan Main sekarang
            mendadak bisa jadi sultan!
          </p>
          <a
            style={{ textDecoration: "none" }}
            className="btn-wortel-primary-orange btn-aboutus"
            href="https://wortel21.com/login"
          >
            Ayo Main Sekarang !
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutUs;
